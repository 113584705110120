import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Button from "../components/ui/button"
import TextGradient from "../components/ui/text-gradient"
import Social from "../components/ui/social"

import defaultThumbnail from "../images/thumbnail-loadmill.png"

const JobApplicationThankYou = ({ location }) => {
  const data = ""
  const title = data?.title || "Thank you for applying"
  const description =
    data?.description ||
    "Thank you for your submission, we will be in touch shortly!"
  const thumbnail = data?.featuredImage?.resize?.src || defaultThumbnail

  return (
    <Layout location={location} className="bg-black">
      <Seo
        title={title}
        description={description}
        image={thumbnail}
        url={location.href}
      />
      <div className="h-screen w-screen flex items-center text-white">
        <div className="container">
          <h1>
            <TextGradient>Thank you!</TextGradient>
          </h1>

          <p className="text-xl">
            Thank you for your submission, we will be in touch shortly!
          </p>

          <Button
            label={"Back to homepage"}
            color="blue"
            to={"/"}
            className="mt-10"
          />

          <div className="my-20">
            <h2 className="mb-6 text-2xl text-gray-light">Let's Connect</h2>

            <Social size="lg" className="!justify-start" />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default JobApplicationThankYou
