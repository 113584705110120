import * as React from "react"

const TextGradient = ({ children }) => {
  return (
    <span className="bg-clip-text inline-block text-transparent bg-gradient-to-r from-blue to-blue-pacific">
      {children}
    </span>
  )
}

export default TextGradient
